import React, { useState, useEffect } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
//import clsx from 'clsx';
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Logo from "./logo.png";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
//import CurrencyTextField from '@unicef/material-ui-currency-textfield'

const styles = theme => ({
  ...theme.spreadableObject
});
function Form(props) {
  const [textColor, setTextColor] = useState("#01718f");
  const [state, setState] = useState({
    sold_percent: "",
    avg_sale: "",
    your_upsell: "",
    upsell_goal: ""
  });
  const [finalScore, setFinalScore] = useState(0);

  const currencyFormatter = event => {
    event.target.value = event.target.value.replace(/[^0-9,]/g, "");
    console.log(event.target.value);
    if (event.target.value !== "" && event.target.value !== undefined) {
      if (event.target.value.length > 9) {
        event.target.value = event.target.value.slice(0, 9);
      }
      var num = event.target.value.replace(/(\s)/g, "");
      let num2 = num.replaceAll(",", "");
      console.log(num2);
      let n = num2.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
      console.log(n);
      let n2 = n.split(" ");
      console.log(n2);
      if (n2[1] === undefined && n2[2] === undefined) {
        event.target.value = n2[0];
      } else if (n2[2] === undefined && typeof n2[1] !== "undefined") {
        let stringVar = n2[0] + "," + n2[1];
        event.target.value = stringVar;
      } else {
        let stringVar = n2[0] + "," + n2[1] + "," + n2[2];
        event.target.value = stringVar;
      }
    } else {
      event.target.value = "";
    }

    setState({ ...state, [event.target.name]: event.target.value });
  };

  const [isSelected, setIsSelected] = useState(false);
  const [isSelected1, setIsSelected1] = useState(false);
  const [isSelected2, setIsSelected2] = useState(false);
  const iconAdornment = isSelected
    ? {
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        maxLength: 5
      }
    : { maxLength: 5 };
  const iconAdornment1 = isSelected1
    ? {
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        maxLength: 5
      }
    : { maxLength: 5 };
  const iconAdornment2 = isSelected2
    ? {
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
        maxLength: 5
      }
    : { maxLength: 5 };
  const checkSelected = event => {
    if (event.target.name === "avg_sale" && event.target.value === "") {
      setIsSelected(false);
    } else if (
      event.target.name === "your_upsell" &&
      event.target.value === ""
    ) {
      setIsSelected1(false);
    } else if (
      event.target.name === "upsell_goal" &&
      event.target.value === ""
    ) {
      setIsSelected2(false);
    }
  };
  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.value });
  };
  const resetState = () => {
    setState({
      sold_percent: "",
      avg_sale: "",
      your_upsell: "",
      upsell_goal: ""
    });
  };

  useEffect(() => {
    console.log(state);
    let avg_sale_var = parseInt(state.avg_sale.replace(",", ""));
    let your_upsell_var = parseInt(state.your_upsell.replace(",", ""));
    let upsell_goal_var = parseInt(state.upsell_goal.replace(",", ""));

    let sold_percent_score = 0,
      avg_sale_score = 0,
      upsell_score = 0,
      finalScoreVar;
    if (
      state.sold_percent !== "" &&
      state.avg_sale !== "" &&
      state.your_upsell !== "" &&
      state.upsell_goal !== ""
    ) {
      if (state.sold_percent <= 66) {
        sold_percent_score = 0.8 * state.sold_percent;
      } else if (state.sold_percent > 66 && state.sold_percent <= 71) {
        sold_percent_score = 0.85 * state.sold_percent;
      } else if (state.sold_percent > 71 && state.sold_percent <= 74) {
        sold_percent_score = 0.88 * state.sold_percent;
      } else if (state.sold_percent > 74 && state.sold_percent <= 76) {
        sold_percent_score = 0.9 * state.sold_percent;
      } else if (state.sold_percent > 76 && state.sold_percent <= 81) {
        sold_percent_score = 0.91 * state.sold_percent;
      } else if (state.sold_percent > 81 && state.sold_percent <= 86) {
        sold_percent_score = 0.93 * state.sold_percent;
      } else if (state.sold_percent > 86) {
        sold_percent_score = 80;
      }

      if (avg_sale_var < 4400) {
        avg_sale_score = 0;
      } else if (avg_sale_var >= 4400 && avg_sale_var < 4450) {
        avg_sale_score = 1;
      } else if (avg_sale_var >= 4450 && avg_sale_var < 4500) {
        avg_sale_score = 2;
      } else if (avg_sale_var >= 4500 && avg_sale_var < 4550) {
        avg_sale_score = 3;
      } else if (avg_sale_var >= 4550 && avg_sale_var < 4600) {
        avg_sale_score = 4;
      } else if (avg_sale_var >= 4600 && avg_sale_var < 4650) {
        avg_sale_score = 5;
      } else if (avg_sale_var >= 4650 && avg_sale_var < 4700) {
        avg_sale_score = 6;
      } else if (avg_sale_var >= 4700 && avg_sale_var < 4750) {
        avg_sale_score = 7;
      } else if (avg_sale_var >= 4750 && avg_sale_var < 4800) {
        avg_sale_score = 8;
      } else if (avg_sale_var >= 4800 && avg_sale_var < 4850) {
        avg_sale_score = 9;
      } else if (avg_sale_var >= 4850) {
        avg_sale_score = 10;
      }

      console.log(your_upsell_var + "&nbsp;" + upsell_goal_var);
      upsell_score = (your_upsell_var / upsell_goal_var) * 10;
      if (upsell_score > 10) {
        upsell_score = 10;
      } else {
      }
      console.log(upsell_score);
      finalScoreVar = sold_percent_score + avg_sale_score + upsell_score;
    } else {
      finalScoreVar = 0;
    }

    if (finalScoreVar < 70 && finalScoreVar !== 0) {
      setTextColor("red");
    } else if (finalScoreVar >= 70 && finalScore <= 85) {
      setTextColor("#01718f");
    } else if (finalScoreVar > 85 && finalScore <= 100) {
      setTextColor("green");
    } else {
      setTextColor("#01718f");
    }

    if (finalScoreVar > 100) {
      setFinalScore(100);
    } else {
      setFinalScore(Math.round(finalScoreVar));
    }
  }, [state, textColor, finalScore]);
  const classes = props.classes;

  return (
    <Grid container className={classes.form}>
      <Grid item md={12} xs={12} sm={12} style={{ textAlign: "center" }}>
        <img
          src={Logo}
          alt=""
          style={{ width: "200px", marginTop: "15px", marginBottom: "15px" }}
        />
      </Grid>
      <Grid item md={4} sm={1} xs={1}></Grid>
      <Grid
        item
        sm={10}
        xs={10}
        md={4}
        style={{ textAlign: "center" }}
        className={classes.customForm}
      >
        <form className={classes.form} noValidate autoComplete="off">
          <Typography
            variant="h6"
            style={{
              color: "#01718f",
              marginTop: "20px",
              marginBottom: "20px",
              fontWeight: "700"
            }}
          >
            STORE RANK CALCULATOR
          </Typography>
          <div style={{ backgroundColor: "rgba(6, 150, 184, 0.2)" }}>
            <TextField
              className={classes.textField}
              type="number"
              value={state.sold_percent}
              name="sold_percent"
              label="Sold Percentage"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
              onChange={handleChange}
              onInput={e => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 3);
              }}
            />
            <br />
          </div>
          <div style={{ backgroundColor: "rgba(6, 150, 184, 0.1)" }}>
            <TextField
              className={classes.textField}
              type="text"
              value={state.avg_sale}
              name="avg_sale"
              label="Average Sale"
              InputProps={iconAdornment}
              onFocus={e => setIsSelected(true)}
              onBlur={
                checkSelected
              } /*onInput={(e)=>{ e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,7)}}*/
              onChange={currencyFormatter}
            />
            <br />
          </div>

          <div style={{ backgroundColor: "rgba(6, 150, 184, 0.2)" }}>
            <TextField
              className={classes.textField}
              type="text"
              value={state.your_upsell}
              name="your_upsell"
              label="Your Upsells"
              InputProps={iconAdornment1}
              onFocus={e => setIsSelected1(true)}
              onBlur={checkSelected}
              onChange={currencyFormatter}
            />
            <br />
          </div>
          <div style={{ backgroundColor: "rgba(6, 150, 184, 0.1)" }}>
            <TextField
              className={classes.textField}
              type="text"
              value={state.upsell_goal}
              name="upsell_goal"
              label="Upsell Goal"
              InputProps={iconAdornment2}
              onFocus={e => setIsSelected2(true)}
              onBlur={checkSelected}
              onChange={currencyFormatter}
            />
            <br />
          </div>
          <br />
          <div
            style={{
              paddingLeft: "8%",
              paddingRight: "8%",
              marginBottom: "20px"
            }}
          >
            <Typography
              variant="h4"
              style={{ color: textColor, textAlign: "left" }}
            >
              Your Score<sup className={classes.supText}>4</sup> = {finalScore}
            </Typography>
            <br />
            <Typography
              variant="body1"
              className={classes.bottomText}
              align="left"
            >
              <sup>1</sup>80 Pts are awarded for Sold Percentage over 86% (Sold
              % Goal = 74%)
            </Typography>

            <Typography
              variant="body1"
              className={classes.bottomText}
              align="left"
            >
              <sup>2</sup>10 Pts are awarded for Average Sale over $4850
            </Typography>

            <Typography
              variant="body1"
              className={classes.bottomText}
              align="left"
            >
              <sup>3</sup>10 Pts are awarded for hitting your upsell goal
            </Typography>

            <Typography
              variant="body1"
              className={classes.bottomText}
              align="left"
            >
              <sup>4</sup>100 is the maximum score
            </Typography>
          </div>
        </form>
      </Grid>
      <Grid item md={4} sm={1} xs={1}>
        {" "}
      </Grid>
      <Grid item md={12} xs={12} sm={12} style={{ textAlign: "center" }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          className={classes.reset_button}
          onClick={resetState}
        >
          Reset
        </Button>
      </Grid>
    </Grid>
  );
}
Form.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(styles)(Form);
