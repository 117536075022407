import React from 'react';
//import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import { MuiThemeProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import Form from './Form.js'
//pages

//import Navbar from './components/Navbar.js';


const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Open Sans',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      light: '#8eacbb',
      main: '#0696b8',
      dark: '#34515e',
      contrastText: '#fff',
    },
    secondary: {
      light: '#efefef',
      main: '#bdbdbd',
      dark: '#8d8d8d',
      contrastText: '#fff',
    }
  },
  spreadableObject: {
    typography: {
      useNextVariants: true
    },
    pageTitle: {},
    form: {
      textAlign: 'center',
     
    },
    image: {},
    textField: { margin: '10px auto 10px auto', width: '80%' },
    button: { position: 'relative' },
    customError: { color: 'red', fontSize: '.8rem', margin: 15 },
    spinner: {},
    customForm: {backgroundColor: 'white', borderRadius :'15px', border: '5px solid #01718f'},
    bottomText :{fontStyle: 'italic', color:'#01718f', fontSize:'12px', marginBottom:'5px'},
    supText: {fontSize : '16px !important'},
    reset_button: {margin: '10px', backgroundColor:'#0696b8', color:'white'}
    
  }
});


function App(props) {


  
  
  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
      <Form/>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
